import '../libs/jquery.actual';
import { CLASSES } from '../_consts';

export default function productTabs() {

  const $tabs = $('.js-product-tab');
  const $tabsContent = $('.js-product-tab-content');
  const $tabsToggle = $('.js-product-tab-toggle');
  const { isActive } = CLASSES;

  $tabsToggle.on('click', (e) => {
    const $tab = $(e.currentTarget);
    const $content = $tab.siblings('.js-product-tab-inner');
    $content.slideDown();
    $tab.addClass(isActive);
  });

  $tabs.on('click', (e) => {
    const $tab = $(e.currentTarget);
    const target = $tab.attr('data-tab-target');
    const $content = $(`.js-product-tab-content[data-tab-content="${target}"]`);
    $tabs.removeClass(isActive);
    $tabsContent.removeClass(isActive);


    if ($tab.hasClass('has-all')) {
      $tabsContent.each((index, el) => {
        if (!$(el).hasClass('js-hide-on-all')) {
          $(el).addClass(isActive);
        }
      });
    }

    $tab.addClass(isActive);
    $content.addClass(isActive);
  });

  const $toggler = $('.js-content-swap');
  if (!$toggler.length) return;

  const $content = $toggler.find('.js-content-more');
  if ($content.length) {
    const $more = $toggler.find('.js-toggle-content');

    const hide = window.matchMedia('(max-width: 768px)').matches && $content.actual('outerHeight') < 330
      || window.matchMedia('(min-width: 769px)').matches && $content.actual('outerHeight') < 200;

    if (hide) {
      $content.addClass('is-small');
      if ($more.length) {
        $more.hide();
      }
    }
  }
}
