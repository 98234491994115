import { CLASSES } from './_consts';
import 'arrive';
import calculate from './modules/_calculate';
import colorCodes from './modules/_colorCodes';
import toggler from './modules/_commonToggler';
import oneBuyField from './modules/_oneBuyField';
import productTabs from './modules/_productTabs';
import productSlider from './sliders/product/productSliders';
import productTilesSlider from './sliders/productTiles/productTilesSlider';

document.addEventListener('DOMContentLoaded', () => {

  window.productTilesSlider = productTilesSlider

  const productTilesSliders = Array.prototype.slice.call(document.querySelectorAll('.js-products-tiles-slider-wrap'));
  if (productTilesSliders.length) {
    productTilesSliders.forEach((el) => {
      productTilesSlider(el);
    });
  }

  const productSliders = Array.prototype.slice.call(document.querySelectorAll('.js-product-sliders-wrap'));
  if (productSliders.length) {
    productSliders.forEach((el) => {
      productSlider(el);
    });
  }


  $(document).arrive('.js-counter', (e) => {
    const $el = $(e);
    const quantity = $el.closest('.js-counter').find('.js-counter-input').val();
    if (quantity > 1) $el.find('.js-counter-decrement').addClass(CLASSES.isActive);
    if (quantity < 2) $el.find('.js-counter-decrement').removeClass(CLASSES.isActive);
  });

  productTabs();
  toggler();
  calculate();
  oneBuyField();
  colorCodes();
});
