import Swiper from 'swiper/dist/js/swiper';
import 'ez-plus';
import 'arrive';
import { CLASSES } from '../../_consts';

// TODO: refactor all this mess
// TODO: separate mob and desktop logic
function loadVideo(config) {
  const { el, src } = config;
  if ($(el).hasClass('has-loaded')) return;
  const newIframe = document.createElement('iframe');
  newIframe.src = `https://www.youtube.com/embed/${src}`;
  newIframe.setAttribute('frameborder', '0');
  newIframe.setAttribute('allow', 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture');
  newIframe.setAttribute('allowfullscreen', '1');
  el.appendChild(newIframe);
  $(el).addClass('has-loaded');
}

const productSlider = (wrapper) => {
  const { isHidden, isActive } = CLASSES;

  if (!wrapper) return;

  const isTablet = window.matchMedia('(max-width: 767px)').matches;
  const sliderThumbsEl = wrapper.querySelector('.js-product-thumbs-slider');
  const sliderMainEl = wrapper.querySelector('.js-product-main-slider');
  const sliderMainScrollBar = sliderMainEl.querySelector('.swiper-scrollbar');
  const slides = Array.prototype.slice.call(sliderMainEl.querySelectorAll('.swiper-slide'));
  const slidesLength = slides.length;
  const loop = slidesLength >= 4;
  const centeredSlides = slidesLength >= 4;

  const popupSliderEl = document.querySelector('.js-product-main-zoom-slider');
  const popupSliderThumbsEl = document.querySelector('.js-product-thumbs-zoom-slider');
  const $popupMainPrev = $('.js-zoom-swiper-prev');
  const $popupMainNext = $('.js-zoom-swiper-next');
  const $prev = $('.js-thumbs-swiper-prev');
  const $next = $('.js-thumbs-swiper-next');
  const $zoomPrev = $('.js-thumbs-zoom-swiper-prev');
  const $zoomNext = $('.js-thumbs-zoom-swiper-next');
  const $thumbs = $('.js-thumb-slide');
  const $zoomThumbs = $('.js-zoom-thumb-slide');

  const thumbsPerView = 4;
  let currentIndex = 0;
  // const slidesPerView = sliderEl.dataset.slides ? sliderEl.dataset.slides : 4;
  // const slidesOnDesktopMd = sliderEl.dataset.slidesMd ? sliderEl.dataset.slidesMd : slidesPerView;


  const zoomSliderThumbs = new Swiper(popupSliderThumbsEl, {
    init: false,
    slideToClickedSlide: true,
    loopedSlides: slidesLength,
    initialSlide: 2,
    threshold: 6,
    spaceBetween: 12,
    direction: 'vertical',
    slidesPerView: 4,
    loop,
    loopAdditionalSlides: 0,
    centeredSlides,
    navigation: {
      nextEl: $zoomNext,
      prevEl: $zoomPrev,
    },
  });

  const zoomSlider = new Swiper(popupSliderEl, {
    init: false,
    threshold: 6,
    slidesPerView: 1,
    noSwiping: false,
    lazy: {
      loadPrevNext: true,
      // loadPrevNextAmount: 3
    },
    // observer: true,
    thumbs: {
      swiper: zoomSliderThumbs
    },
    navigation: {
      nextEl: $popupMainNext,
      prevEl: $popupMainPrev,
    },
    loop: true,
    loopedSlides: slidesLength,
    on: {
      slideChangeTransitionEnd() {
        $(popupSliderEl).find('iframe').remove();
        $(popupSliderEl).find('.has-loaded').removeClass('has-loaded');
        const slidesArray = Array.prototype.slice.call(this.slides);
        const $currentSlide = $(slidesArray[this.activeIndex]);
        if ($currentSlide.hasClass('is-video')) {
          const videoEl = $currentSlide.find('.js-slide-video');
          const src = videoEl.attr('data-video');
          loadVideo({ el: videoEl[0], src} );
        }
        if (isTablet) return;
        if (slidesLength <= thumbsPerView) {
          $zoomThumbs.removeClass(isActive);
          $('.js-product-thumbs-zoom-slider ').find('.swiper-slide').eq(this.realIndex).addClass(isActive);
        }
      }
    }
  });


  function initZoom() {
    if (isTablet) return;
    $(sliderMainEl)
      .find('.swiper-slide-active')
      .find('.js-zoom')
      .ezPlus({
        zIndex: 3,
      });
  }

  function initPopupSliders() {
    if (isTablet) return;
    zoomSlider.params.initialSlide = currentIndex;
    zoomSliderThumbs.init();
    zoomSlider.init();
    zoomSlider.slideTo(currentIndex, 0, false);
    setTimeout(() => {
      zoomSlider.slideTo(currentIndex, 0, false);
      setTimeout(() => {
        zoomSliderThumbs.update();
        zoomSlider.update();

        const slidesArray = Array.prototype.slice.call(zoomSlider.slides);
        const $currentSlide = $(slidesArray[zoomSlider.activeIndex]);
        if ($currentSlide.hasClass('is-video')) {
          const videoEl = $currentSlide.find('.js-slide-video');
          const src = videoEl.attr('data-video');
          loadVideo({ el: videoEl[0], src} );
        }

        if (slidesLength <= thumbsPerView) {
          $zoomThumbs.removeClass(isActive);
          $('.js-product-thumbs-zoom-slider ').find('.swiper-slide').eq(currentIndex).addClass(isActive);
        }
      }, 50);
    }, 200);
  }

  // observe zoom containers to reinit zoom and modal logic
  $(document).arrive('.zoomContainer', () => {
    if (isTablet) return;
    const $container = $('.zoomContainer');
    if ($container.length) {
      $container.addClass('js-modal-link');
      $container.attr('data-modal-target', 'product-zoom-popup');
      window.modal.destroy();
      window.modal.init();
      $container.on('click', () => {
        initPopupSliders();
      });
    }
  });



  const thumbsOptions = {
    slideToClickedSlide: true,
    loopedSlides: slidesLength,
    initialSlide: slidesLength > 2 ? 1 : 0,
    threshold: 6,
    spaceBetween: 12,
    direction: 'vertical',
    slidesPerView: thumbsPerView,
    loop,
    loopAdditionalSlides: 0,
    centeredSlides,
    navigation: {
      nextEl: $next,
      prevEl: $prev,
    },
    // lazy: {
    //   loadPrevNext: true,
    //   loadPrevNextAmount: 3
    // },
    // on: {
    //   slideChangeTransitionEnd() {
    //     window.instances.forEach((ins) => {
    //       ins.update();
    //     });
    //   }
    // },
    // breakpoints: {
    //   768: {
    //     slidesPerView: 'auto',
    //   },
    //   1024: {
    //     slidesPerView: 3,
    //   },
    //   1220: {
    //     slidesPerView: slidesOnDesktopMd,
    //   }
    // }
  };

  const thumbsSlider = new Swiper(sliderThumbsEl, thumbsOptions);

  const mainOptions = {
    init: false,
    threshold: 6,
    slidesPerView: 1,
    loop: loop && !isTablet,
    loopedSlides: slidesLength,
    noSwiping: !isTablet,
    lazy: {
      loadPrevNext: true,
      // loadPrevNextAmount: 3
    },
    thumbs: {
      swiper: thumbsSlider,
    },
    scrollbar: {
      el: sliderMainScrollBar,
    },
    on: {
      init() {
        const slidesArray = Array.prototype.slice.call(this.slides);
        const $currentSlide = $(slidesArray[this.activeIndex]);
        if ($currentSlide.hasClass('is-video')) {
          const videoEl = $currentSlide.find('.js-slide-video');
          const src = videoEl.attr('data-video');
          loadVideo({ el: videoEl[0], src} );
        }

        if (isTablet) return;
        thumbsSlider.update();
        initZoom();
        currentIndex = this.realIndex;
        $('.swiper-slide.js-thumb-slide').on('mouseenter', (e) => {
          const $el = $(e.currentTarget);
          this.slideTo($el.index());
          $el.addClass('js-modal-link');
          $el.attr('data-modal-target', 'product-zoom-popup');
          window.modal.destroy();
          window.modal.init();
          if (loop) return;
          $thumbs.removeClass(isActive);
          $(e.currentTarget).addClass(isActive);
        });
      },
      slideChangeTransitionStart() {
        if (isTablet) return;
        $('.zoomContainer').remove();
        initZoom();
        zoomSlider.update();
      },
      slideChangeTransitionEnd() {
        const slidesArray = Array.prototype.slice.call(this.slides);
        const $currentSlide = $(slidesArray[this.activeIndex]);
        if ($currentSlide.hasClass('is-video')) {
          const videoEl = $currentSlide.find('.js-slide-video');
          const src = videoEl.attr('data-video');
          loadVideo({ el: videoEl[0], src} );
        }
        if (isTablet) return;
        currentIndex = this.realIndex;
        zoomSlider.update();
      }
    },
    effect: isTablet ? false : 'fade',
    fadeEffect: {
      crossFade: true
    },
  };

  const mainSlider = new Swiper(sliderMainEl, mainOptions);

  mainSlider.init();


  if (isTablet) return;

  if (thumbsSlider.slides.length <= thumbsPerView) {
    $prev.addClass(isHidden);
    $next.addClass(isHidden);
    $zoomPrev.addClass(isHidden);
    $zoomNext.addClass(isHidden);
    $('.js-product-thumbs-slider ').find('.swiper-slide-active').addClass(isActive);
  }

  if (thumbsSlider.slides.length <= 1) {
    $popupMainPrev.addClass(isHidden);
    $popupMainNext.addClass(isHidden);
  }

  $('.js-open-gallery').on('click', () => {
    initPopupSliders();
  });

  // fix for thumbs if thumbs are less then slidesPerView
  $thumbs.on('click', (e) => {
    initPopupSliders();
    if (loop) return;
    $thumbs.removeClass(isActive);
    $(e.currentTarget).addClass(isActive);
  });
  $zoomThumbs.on('click', (e) => {
    if (loop) return;
    $zoomThumbs.removeClass(isActive);
    $(e.currentTarget).addClass(isActive);
  });

};
export default productSlider;
