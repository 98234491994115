import { CLASSES } from '../_consts';

export default function oneBuyField() {
  const $link = $('.js-one-click-action');

  $link.on('click', (e) => {
    const $el = $(e.currentTarget);
    const $wrapper = $el.closest('.js-one-click-block');
    $wrapper.addClass(CLASSES.isActive);
  })
}
