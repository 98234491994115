export default function calculate() {
  const $wrapper = $('.js-calculate-wrapper');
  const $btn = $wrapper.find('.js-calculate');

  const $widthEl = $wrapper.find('.js-calc-width');
  const $heightEl = $wrapper.find('.js-calc-height');
  const $squareEl = $wrapper.find('.js-calc-square');
  let width;
  let height;
  let square;

  function calculateSquare() {
    width = parseFloat($widthEl.val().replace(',', '.'));
    height = parseFloat($heightEl.val().replace(',', '.'));

    if (Number.isNaN(width) || Number.isNaN(height)) return;

    square = width * height;
    $squareEl.val(square);
  }

  $widthEl.on('input', calculateSquare);
  $heightEl.on('input', calculateSquare);

  $squareEl.on('focusout', (e) => {
    $squareEl.val(Number(e.target.value.replace(',', '.')));
  })

  $btn.on('click', () => {
    square = Number($squareEl.val());
    if (!square || Number.isNaN(square)) return;
    const sum = square/10 * 2;
    $('.js-volume-wrapper').show();
    $('.js-volume').text(sum.toFixed(2).toString().replace('.', ','));
  });
}
