import { CLASSES } from '../_consts';


export default function toggler() {

  const $links = $('.js-slide-toggle-link');
  const { isActive } = CLASSES;

  const header = document.querySelector('.js-header');
  const headerMob = document.querySelector('.js-mob-header');
  const headerHeight = $(header).outerHeight() || $(headerMob).outerHeight() || 0;


  $links.on('click', (e) => {
    const $link = $(e.currentTarget);
    const $wrapper = $link.closest('.js-slide-toggle-parent');
    const $innerContents = $wrapper.find('.js-slide-toggle-content');
    const $innerLinks = $wrapper.find('.js-slide-toggle-link');
    const $content = $link.siblings('.js-slide-toggle-content');
    const scrollTo = $link.attr('data-scroll-to');

    if ($link.hasClass(isActive)) {
      $innerContents.slideUp();
      $innerLinks.removeClass(isActive);
      $innerContents.removeClass(isActive);
    } else {
      $innerContents.slideUp();
      $innerLinks.removeClass(isActive);
      $innerContents.removeClass(isActive);

      $content.slideDown();
      $link.addClass(isActive);
      $content.addClass(isActive);

      if (scrollTo) {
        $('html, body').animate(
          {
            scrollTop: $link.offset().top - headerHeight
          }, {
            duration: 500,
            // stepping animation cause of lazy loading updated positioning
            step(now, fx) {
              const newOffset = $link.offset().top - headerHeight;
              if (fx.end !== newOffset)
                // eslint-disable-next-line no-param-reassign
                fx.end = newOffset;
            }
          }
        );
      }
    }
  });
}
