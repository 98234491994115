import { DOM } from '../_consts';


export default function colorCodes() {
  const { $body } = DOM;
  const $ccWrapper = $('.js-color-codes');
  let $rows = $('.js-color-code-row');
  let $deleteButtons = $('.js-color-codes-remove');
  let $rowsInputs = $rows.find('input');

  $(document).arrive(".js-color-code-row", () => {
    $rows = $('.js-color-code-row');
    $deleteButtons = $('.js-color-codes-remove');
    $rowsInputs = $rows.find('input');

    $rowsInputs.on('input', (e) => {
      if ($rows.length > 1) return;

      if ($(e.target).val().length > 0) {
        $deleteButtons.show();
      } else {
        $deleteButtons.hide();
      }
    });
  });

  $body.on('click', '.js-color-codes-add', (e) => {
    e.preventDefault();
    const row = document.querySelector('.js-color-code-row');
    const rowsLength = $('.js-color-code-row').length;

    $deleteButtons.show();
    const $newRow = $(row).clone();
    const $input = $newRow.find('input');
    const $label = $newRow.find('label');
    $input.attr('name', `color_code_${rowsLength}`);
    $input.attr('id', `color_code_${rowsLength}`);
    $label.attr('for', `color_code_${rowsLength}`);
    $newRow.appendTo($ccWrapper);
    $ccWrapper
      .find('.js-color-code-row')
      .last()
      .find('input')
      .val('');
  });

  $rowsInputs.on('input', (e) => {
    if ($rows.length > 1) return;

    if ($(e.target).val().length > 0) {
      $deleteButtons.show();
    } else {
      $deleteButtons.hide();
    }
  });

  $body.on('click', '.js-color-codes-remove', (e) => {
    e.preventDefault();
    $rows = $('.js-color-code-row');
    $deleteButtons = $('.js-color-codes-remove');
    const rowsLength = $rows.length;

    if (rowsLength <= 1) {
      $rows.find('input').val('');
      $deleteButtons.hide();
      return;
    }

    $(e.currentTarget).closest('.js-color-code-row').remove();
    if (rowsLength <= 2) {
      if (!$rows.find('input').val().length) {
        $deleteButtons.hide();
      }
    }

    $('.js-color-code-row').each((index, el) => {
      const $el = $(el);
      const $input = $el.find('input');
      const $label = $el.find('label');
      $input.attr('name', `color_code_${index}`);
      $input.attr('id', `color_code_${index}`);
      $label.attr('for', `color_code_${index}`);
    });
  });
}
